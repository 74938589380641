
/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

//require('./bootstrap');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the body of the page. From here, you may begin adding components to
 * the application, or feel free to tweak this setup for your needs.
 */
/*
Vue.component('example', require('./components/Example.vue'));



const app = new Vue({
    el: '#app'
});
*/

$(document).ready(function(e) {

  if($('#errorsModal').length) {
    $('#errorsModal').modal('show');
  }

  $('.list-group .list-group-item form.removeComplaint').submit(function(event) {
    event.preventDefault();
    var removeID = $(this).find('input').val();
    $('.removeComplaintModal').attr('action','/complaints/remove/' + removeID);
  });
	
  $('input[type="checkbox"]').each(function(iterate) {
    this.value = (Number(this.checked));
  });

  $('input[type="checkbox"]').change(function(){
      this.value = (Number(this.checked));
  });

  $('.btn-respond').click(function(e) {
    $(this).parent().toggleClass('disabled');
  });

});